import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { Link, Route, Routes } from "react-router-dom";
import logo from "@/ui/assets/logo.png";
import { ModeToggle } from "@/ui/components/theme-toggle.js";
import { HealthStatus } from "@/ui/components/health.js"
import { LogInOut } from "@/ui/components/logInOut.js"
import { MailButton } from "@/ui/components/mailButton.js"
import { PrivacyPolicy, TermsOfService } from "@/ui/components/legal.js"
import { DonateButton, DonateLink } from "@/ui/components/donate.js"
import '../assets/home.css';
import { GameInstructions } from "@/ui/components/instructions.js";

function Home() {

  return (
    <div className="Home">
      <div className="text-center">
        <img
          src={logo}
          className="logo"
          alt="logo"
        />
      </div>
      <h1>Diplomabot</h1>
      <br/>
      <div className="card">
        <LogInOut/>
        <MailButton/>
        <ModeToggle/>
        <DonateButton/>
      </div>
      <br/>
      <GameInstructions/>
      <br/>
      <footer className="w-dvw h-8 fixed inset-x-0 bottom-0 bg-white dark:bg-slate-950">
        <div className="flex flex-row gap-2 place-content-center">
          <HealthStatus/>
          |
          <TermsOfService/>
          |
          <PrivacyPolicy/>
          |
          <DonateLink/>
        </div>
      </footer>
    </div>
  );
}

export default Home;
