import { useAtom } from 'jotai';
import { userAtom } from '@/ui/atoms/user.js';
import { Button } from "@/ui/components/shadcn/button.js"
import { MailIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
  } from "@/ui/components/shadcn/tooltip.js"

export const MailButton = () => {
    const [user, setUser] = useAtom(userAtom);
    const navigate = useNavigate()

    return user && 
    <TooltipProvider>
        <Tooltip>
        <TooltipTrigger asChild>
            <Button variant="outline" size="icon" onClick={()=>navigate('/mail')} >
                <MailIcon/>
            </Button>
        </TooltipTrigger>
            <TooltipContent>
                Email
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>

} 