import React, { useState }  from 'react';
import ReactDOM from 'react-dom/client'
import { Link, Route, Routes } from 'react-router-dom'
import { Button } from '@/ui/components/shadcn/button.js'
import kissinger from "@/ui/assets/kissinger.jpg";
import { cn } from "@/ui/lib/utils.js"
import { buttonVariants } from "@/ui/components/shadcn/button.js"
import { UserAuthForm } from "@/ui/components/user-auth-form.js"

export const metadata = {
  title: "Authentication",
  description: "Authentication forms built using the components.",
}

export default function AuthenticationPage() {
  return (
    <>
      <div className="container:grid relative h-dvh w-dvw flex-col items-center justify-center sm:grid md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
          <div className="absolute inset-0 bg-zinc-900" />
          <div className="relative z-20 flex items-center text-lg font-medium">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mr-2 h-6 w-6"
            >
              <path d="M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3" />
            </svg>
            Diplomabot
          </div>
          <div className="relative">
          <img
              src={kissinger}
              width={800}
              height={400}
              alt="Authentication"
            />
          </div>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">
                &ldquo;Empires have no interest in operating within an international system; they aspire to be the international system.
                …Policy is the art of the possible, the science of the relative.&rdquo;
              </p>
              <footer className="text-sm">Henry Kissinger</footer>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Log In
              </h1>
              <p className="text-sm text-muted-foreground">
                Auth component below, you know what you're doing
              </p>
            </div>
            <UserAuthForm />
          </div>
        </div>
      </div>
    </>
  )
}
