"use client"

import * as React from "react"

import { cn } from "@/ui/lib/utils.js"
import { Icons } from "@/ui/components/icons.js"
import { Button } from "@/ui/components/shadcn/button.js"
import { Input } from "@/ui/components/shadcn/input.js"
import { Label } from "@/ui/components/shadcn/label.js"
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { base64url } from "jose";
import { userAtom } from '@/ui/atoms/user.js';

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [user, setUser] = useAtom(userAtom);
  const navigate = useNavigate();

  const {isPending, mutate: login} = useMutation({
    mutationFn: async () => {
      return fetch('/login',{
        method:'post',
        body: base64url.encode(JSON.stringify({email,password}))
      })
    },
    onError: async (error) => {
      alert(error);
    },
    onSuccess: async(response) => {
      if (response.ok) {
        setUser({email,token: await response.text()});
        navigate('/');
      } else {
        setUser(undefined);
        alert(await response.text())
      }

    }
  });

  async function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault()
    login()
  }

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <form onSubmit={onSubmit} action="">
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <Input
              id="email"
              placeholder="name@example.com"
              type="email"
              value={email}
              onInput={(e)=>setEmail(e.currentTarget.value)}
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isPending}
            />
            <Input
              id="password"
              placeholder="password"
              type="password"
              value={password}
              onInput={(e)=>setPassword(e.currentTarget.value)}
              autoCapitalize="none"
              autoComplete="password"
              autoCorrect="off"
              disabled={isPending}
            />
          </div>
          <Button disabled={isPending}>
            {isPending && (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            )}
            Sign In
          </Button>
        </div>
      </form>
    </div>
  )
}
