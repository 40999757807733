import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
//import { atomWithQuery } from 'jotai-tanstack-query'
import { healthAtom } from '@/ui/atoms/healthCheck.js';
import { userAtom } from '@/ui/atoms/user.js';

export function HealthStatus(props:any) {

    const [health, setHealth] = useAtom(healthAtom);
    const [user, setUser] = useAtom(userAtom);

    const { isPending, error, data } = useQuery({
        queryKey: ['healthCheck'],
        refetchInterval: 60000,
        queryFn: () =>
          fetch('/health',{
            headers: user ? { "Authorization": `Bearer ${user.token}` } : {}
          }).then((res) => {
            if (res.ok) {
                setHealth(true);
                return res.text();
            } else {
                throw {code:res.status,message:res.text()}
            }
          }).catch(
            (error)=>{
                setHealth(false);
                if (error.code == 401) setUser(undefined);
                throw error
            }
          ),
      })

    return <div>
        {isPending ? 'Pending HealthCheck' : !!error ? `${error.message}` : `Status: ${data}`}
        &nbsp;
        {isPending ? '⏳' : health ? '✅' : '❌'}
    </div> 
}